<script setup lang="ts">
const model = defineModel<string | number | undefined>()

const passwordVisibility = ref(false)
</script>

<template>
  <!-- WORKAROUND: UInput #trailing slot is behind input and not clickable -->
  <div class="relative flex items-center">
    <UInput
      v-model="model"
      :type="passwordVisibility ? 'text' : 'password'"
      autocomplete="new-password"
      v-bind="$attrs"
      class="w-full"
    />
    <UButton
      variant="ghost"
      color="gray"
      :padded="false"
      :icon="passwordVisibility ? 'i-mdi-eye-off' : 'i-mdi-eye'"
      class="absolute right-2 z-10"
      @click="() => passwordVisibility = !passwordVisibility"
    />
  </div>
</template>
